import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "instalação-do-cmr-protected-para-mta"
    }}>{`Instalação do CMR Protected para MTA`}</h1>
    <p>{`Para adicionar o nosso módulo ao seu servidor Multi Theft Auto (MTA), siga os passos abaixo:`}</p>
    <h2 {...{
      "id": "1-faça-o-download-do-módulo"
    }}>{`1. Faça o Download do Módulo`}</h2>
    <p>{`Faça o download da versão para Linux `}<a parentName="p" {...{
        "href": "/download#linux"
      }}>{`aqui`}</a>{`, compatível com a arquitetura do seu MTA (x86 ou x64).`}</p>
    <h2 {...{
      "id": "2-coloque-o-módulo-no-diretório-do-servidor"
    }}>{`2. Coloque o Módulo no Diretório do Servidor`}</h2>
    <p>{`Transfira o arquivo do módulo para o diretório do seu servidor MTA:`}</p>
    <ul>
      <li parentName="ul">{`Para servidores x64: Geralmente localiza-se em `}<inlineCode parentName="li">{`seu_servidor/x64/modules`}</inlineCode>{`. Se a pasta não existir, crie-a.`}</li>
      <li parentName="ul">{`Para servidores x86: Instale o módulo em `}<inlineCode parentName="li">{`seu_servidor/mods/deathmatch/modules`}</inlineCode>{`. Se a pasta não existir, crie-a.`}</li>
    </ul>
    <h2 {...{
      "id": "3-configure-o-módulo"
    }}>{`3. Configure o Módulo`}</h2>
    <p>{`Adicione a seguinte linha ao arquivo `}<inlineCode parentName="p">{`mtaserver.conf`}</inlineCode>{` do seu servidor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<module src="cmr_protected.so"/>
`}</code></pre>
    <h2 {...{
      "id": "5-reinicie-o-servidor"
    }}>{`5. Reinicie o Servidor`}</h2>
    <p>{`Após colocar o módulo no diretório do servidor e configurá-lo, reinicie o servidor MTA para que o módulo seja carregado e comece a funcionar. Você pode fazer isso usando o painel de controle do seu servidor.`}</p>
    <hr></hr>
    <p>{`© 2024 CMR Services. Todos os direitos reservados.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      